import React from 'react';

function TranscriptArea({ transcript, handleTranscriptAreaChange }) {
  return (
    <div className="mt-6">
      <h2 className="section-title">Transcript:</h2>
      <textarea
        className="text-box"
        value={transcript}
        onChange={handleTranscriptAreaChange}
        name="transcript"
      />
    </div>
  );
}

export default TranscriptArea;
