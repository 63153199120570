// src/Router.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FrontPage from './FrontPage';
import App from './App';
import Login from './Login';
import SignUp from './SignUp';
import BillingPage from './BillingPage';
import PrivateRoute from './PrivateRoute';  // Import the PrivateRoute component

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/billing" element={<BillingPage />} />
        <Route 
          path="/app" 
          element={
            <PrivateRoute>
              <App />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
}

export default AppRouter;
