import React from 'react';

const TranscribeButton = ({ onClick }) => {
  return (
    <button className='btn mt-4' onClick={onClick}>
      Transcribe Audio
    </button>
  );
};

export default TranscribeButton;
