import { db } from './firebase';
import { doc, setDoc } from 'firebase/firestore';

export async function createUserData(user) {
  const userRef = doc(db, "astrovoiceusers", user.uid);  // 'astrovoiceusers' collection
  await setDoc(userRef, {
    email: user.email,
    subscription_status: 'inactive',
    subscription_expiration: null
  }, { merge: true });  // merge: true prevents overwriting existing data
}
