import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { checkUserSubscription } from './CheckUserData';
import AudioRecorder from './AudioRecorder';
import TranscribeButton from './TranscribeButton';
import TranscriptArea from './TranscriptArea';
import ProofreadArea from './ProofreadArea';

function App() {
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcript, setTranscript] = useState('');
  const [proofreadText, setProofreadText] = useState('');
  const [loading, setLoading] = useState(true);
  const [copyStatus, setCopyStatus] = useState({ transcript: false, proofread: false });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const subscription = await checkUserSubscription(user.uid);
        if (subscription && subscription.subscription_status === ('active' || 'admin')) {
          setLoading(false);  // User is authenticated and subscription is active
        } else {
          navigate('/billing');  // Redirect to billing if subscription is inactive or expired
        }
      } else {
        navigate('/login');  // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();  // Cleanup subscription on unmount
  }, [navigate]);

  const handleAudioBlob = (blob) => {
    setAudioBlob(blob);
  };

  const handleTranscribe = async () => {
    if (!audioBlob) {
      alert('Please record some audio first!');
      return;
    }

    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.webm');

    try {
      const response = await axios.post('https://transcribe-whisper-cross-back.vercel.app/transcribe/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setTranscript(response.data.transcript);

    } catch (error) {
      console.error('Error transcribing audio:', error);
      alert('Error transcribing audio. Please check the console for details.');
    }
  };

  const handleProofread = async () => {
    if (!transcript) {
      alert('Please transcribe audio first!');
      return;
    }

    const formData = new FormData();
    formData.append('text', transcript);

    try {
      
      //Will need to add to transcript part also. 
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      const token = await user.getIdToken();
 
      const response = await axios.post('https://transcribe-whisper-cross-back.vercel.app/proofread/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, //add token to the header for authorisation - will have to do the same for transcription part. 
        },
      });

      setProofreadText(response.data.proofread_text);

    } catch (error) {
      console.error('Error proofreading text:', error);
      alert('Error proofreading text. Please check the console for details.');
    }
  };

  const handleCopyToClipboard = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyStatus((prevStatus) => ({ ...prevStatus, [field]: true }));
      setTimeout(() => {
        setCopyStatus((prevStatus) => ({ ...prevStatus, [field]: false }));
      }, 2000);
    });
  };

  function handleTranscriptAreaChange(event) {
    const textarea = event.target;
    textarea.style.height = 'auto';  // Reset height first
    textarea.style.height = `${textarea.scrollHeight + 3}px`;  // Set new height
    setTranscript(textarea.value);  // Update the state with new value
  }

  function handleProofAreaChange(event) {
    const textarea = event.target;
    textarea.style.height = 'auto';  // Reset height first
    textarea.style.height = `${textarea.scrollHeight + 3}px`;  // Set new height
    setProofreadText(textarea.value);  // Update the state with new value
  }

  function resizeTextarea(textareaId) {
    const textarea = document.querySelector(`textarea[name="${textareaId}"]`);
    if (textarea) {
      textarea.style.height = 'auto';  // Reset height first
      textarea.style.height = `${textarea.scrollHeight + 3}px`;  // Set new height
    }
  }

  useEffect(() => {
    resizeTextarea('transcript');
  }, [transcript]);

  useEffect(() => {
    resizeTextarea('proofreadText');
  }, [proofreadText]);

  if (loading) {
    return <div>Loading...</div>;  // Optionally display a loading spinner
  }

  return (
    <div className="App bg-gray-500 min-h-screen flex items-center justify-center">
      <div className="container-box">
        <h1 className="header-text">Legal Transcription</h1>
        <AudioRecorder onAudioBlob={handleAudioBlob} />
        <div className="relative mt-6">
          <TranscriptArea
            transcript={transcript}
            handleTranscriptAreaChange={handleTranscriptAreaChange}
          />
          <button
            className="absolute top-0 right-0 mt-2 mr-2"
            onClick={() => handleCopyToClipboard(transcript, 'transcript')}
          >
            {copyStatus.transcript ? '✔️' : '📋'}
          </button>
        </div>
        <TranscribeButton onClick={handleTranscribe} />
        <div className="relative mt-6">
          <ProofreadArea
            proofreadText={proofreadText}
            handleProofAreaChange={handleProofAreaChange}
            handleProofread={handleProofread}
          />
          <button
            className="absolute top-0 right-0 mt-2 mr-2"
            onClick={() => handleCopyToClipboard(proofreadText, 'proofread')}
          >
            {copyStatus.proofread ? '✔️' : '📋'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
