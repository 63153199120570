import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

export async function checkUserSubscription(userId) {
  const userRef = doc(db, "astrovoiceusers", userId);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const userData = userDoc.data();
    return {
      subscription_status: userData.subscription_status,
      subscription_expiration: userData.subscription_expiration,
    };
  } else {
    console.error("No such document!");
    return null;
  }
}
