import React from 'react';

function ProofreadArea({ proofreadText, handleProofAreaChange, handleProofread }) {
  return (
    <div className="mt-6">
      <h2 className="section-title">Proofread Text:</h2>
      <textarea
        className="text-box"
        value={proofreadText}
        onChange={handleProofAreaChange}
        name="proofreadText"
      />
      <button className="btn mt-4" onClick={handleProofread}>Proofread Text</button>
    </div>
  );
}

export default ProofreadArea;
