import React from 'react';
import { Link } from 'react-router-dom';

function FrontPage() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="container-box">
        <h1 className="header-text">Welcome to Legal Transcription App</h1>
        <p className="text-center text-gray-700 mb-6">
          This app helps you transcribe and proofread legal documents efficiently.
        </p>
        <section className="about-us mb-6">
          <h2 className="section-title">About Us</h2>
          <p className="text-gray-600">
            We are dedicated to providing accurate and efficient transcription services for legal professionals.
          </p>
        </section>
        <div className="auth-options flex justify-between">
          <Link to="/login" className="btn">
            Log In
          </Link>
          <Link to="/signup" className="btn btn-green">
            Create Account
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FrontPage;
