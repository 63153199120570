// src/BillingPage.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';  // This will be your custom checkout form

const stripePromise = loadStripe('pk_live_51PsgYJ2KITgVilUbiQKcBVkuSrq5vBChTNUGKkxjbn9vs2xYkbGARhQ7fKsLcyJdhIgbkswovtfLP8nXli9ibPxR002cWeGPGr');  // Replace with your Stripe public key

function BillingPage() {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
}

export default BillingPage;
