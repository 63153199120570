import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { auth } from './firebase';

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const user = auth.currentUser;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { data } = await axios.post('https://transcribe-whisper-cross-back.vercel.app/stripe/create-checkout-session', {
          payment_method_id: paymentMethod.id,
          customer_id: user.uid,
        });

        window.location.href = data.url;
        setSuccess(true);
      } catch (error) {
        console.error('Error:', error);
        setError('Payment failed. Please try again.');
        setLoading(false);
      }
    } else {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="container-box">
        <h2 className="header-text">Complete Your Subscription</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">Payment successful! Redirecting...</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="mb-4">
            <label className="section-title" htmlFor="card-element">Credit or Debit Card</label>
            <div className="text-box" id="card-element">
              <CardElement />
            </div>
          </div>
          <button 
            type="submit" 
            className={`btn btn-green ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} 
            disabled={!stripe || loading}
          >
            {loading ? 'Processing...' : 'Pay'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default CheckoutForm;
