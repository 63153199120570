// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import {getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyA60zTLfz4-Q1iJ4PX0WSf9UJDz2xVuCGY',
  authDomain: 'astro-voice-275c8.firebaseapp.com',
  projectId: 'astro-voice-275c8',
  storageBucket: 'astro-voice-275c8.appspot.com',
  messagingSenderId: '606365153294',
  appId: '1:606365153294:web:a2ef837deabd934a55a45f',
  measurementId: 'G-FR8MQ6BXGC',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Add this line to initialize Firebase Auth
const db = getFirestore(app);

export { analytics, auth, db };
